import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="70.4" height="80" viewBox="0 0 70.4 80">
        <g id="rebaked_playshare_full" data-name="rebaked playshare full" transform="translate(-388.6 -375.75)">
            <g id="Group_1725" data-name="Group 1725" transform="translate(396.59 375.75)">
                <g id="Group_1721" data-name="Group 1721" transform="translate(6.005)">
                    <g id="Group_1717" data-name="Group 1717">
                        <path id="Path_5968" data-name="Path 5968" d="M489.249,383.5a25.88,25.88,0,0,0-36.942,0,26.666,26.666,0,0,0,0,37.4c.1.1.2.175.3.275L489.52,383.8A2.976,2.976,0,0,0,489.249,383.5Z" transform="translate(-444.65 -375.75)" fill="#102548" />
                    </g>
                    <g id="Group_1718" data-name="Group 1718" transform="translate(11.462 11.76)">
                        <path id="Path_5969" data-name="Path 5969" d="M518.2,427.382A15.5,15.5,0,1,0,496.282,449.3a1.152,1.152,0,0,1,.175.175l21.9-21.9A.691.691,0,0,0,518.2,427.382Z" transform="translate(-491.75 -422.85)" fill="#f9ec80" />
                    </g>
                    <g id="Group_1719" data-name="Group 1719" transform="translate(9.764 10.324)">
                        <path id="Path_5970" data-name="Path 5970" d="M486.25,448.935a22.334,22.334,0,0,0,31.585-31.585,2.351,2.351,0,0,1-.25-.25L486,448.685Z" transform="translate(-486 -417.1)" fill="#1a4791" />
                    </g>
                    <g id="Group_1720" data-name="Group 1720" transform="translate(18.096 18.439)">
                        <path id="Path_5971" data-name="Path 5971" d="M518.625,465.055a10.84,10.84,0,0,0,15.331-15.331l-.125-.125L518.5,464.931Z" transform="translate(-518.5 -449.6)" fill="#f57f20" />
                    </g>
                    <circle id="Ellipse_5169" data-name="Ellipse 5169" cx="4.145" cy="4.145" r="4.145" transform="translate(22.025 22.309)" fill="#645aa7" />
                </g>
                <g id="Group_1724" data-name="Group 1724" transform="translate(0 51.522)">
                    <g id="Group_1723" data-name="Group 1723">
                        <g id="Group_1722" data-name="Group 1722">
                            <path id="Path_5972" data-name="Path 5972" d="M423.247,584.1h3.6a4.028,4.028,0,0,1,2.272.549,2.2,2.2,0,0,1,1,1.548,2.767,2.767,0,0,1-.449,2.072,3.024,3.024,0,0,1-1.124,1.2,5.709,5.709,0,0,1-1.873.649l2.047,3.97h-1.3l-1.973-3.87h-2.7l-1.024,3.87H420.6Zm2.472,5.094a4.534,4.534,0,0,0,1.848-.325,2.324,2.324,0,0,0,1.1-.874,2.1,2.1,0,0,0,.4-1.4,1.421,1.421,0,0,0-.624-1.074,2.965,2.965,0,0,0-1.648-.4h-2.672l-1.1,4.07Z" transform="translate(-420.6 -583.601)" fill="#fff" />
                            <path id="Path_5973" data-name="Path 5973" d="M463.574,598.194v.15a2.067,2.067,0,0,0,2.222,2.222,3.184,3.184,0,0,0,1.248-.225,4.14,4.14,0,0,0,1.024-.649l.574.724a5.1,5.1,0,0,1-1.223.774,3.734,3.734,0,0,1-1.648.325,3.773,3.773,0,0,1-1.323-.225,2.872,2.872,0,0,1-1.024-.649,3.2,3.2,0,0,1-.674-1,3.98,3.98,0,0,1-.25-1.273,4.215,4.215,0,0,1,.325-1.648,4.971,4.971,0,0,1,.9-1.473,4.571,4.571,0,0,1,1.348-1.049,3.618,3.618,0,0,1,1.7-.4,3.056,3.056,0,0,1,1.223.225,2.832,2.832,0,0,1,.9.6,2.362,2.362,0,0,1,.549.9,3.125,3.125,0,0,1,.175,1.074,3.567,3.567,0,0,1-.075.724,6.693,6.693,0,0,1-.175.674H463.6A.7.7,0,0,0,463.574,598.194Zm4.894-.974a1.776,1.776,0,0,0,.075-.574,2.307,2.307,0,0,0-.1-.724,1.743,1.743,0,0,0-.325-.6,1.714,1.714,0,0,0-1.4-.574,2.873,2.873,0,0,0-1.024.2,2.693,2.693,0,0,0-.849.524,3.373,3.373,0,0,0-.649.8,4.26,4.26,0,0,0-.424.974Z" transform="translate(-452.143 -590.879)" fill="#fff" />
                            <path id="Path_5974" data-name="Path 5974" d="M497.747,584h3.72a3.615,3.615,0,0,1,2.272.624,2,2,0,0,1,.8,1.7,2.5,2.5,0,0,1-.175.974,2.359,2.359,0,0,1-.524.774,2.9,2.9,0,0,1-.774.549,3.2,3.2,0,0,1-.974.325,2.948,2.948,0,0,1,1.248.724,1.749,1.749,0,0,1,.524,1.348,2.811,2.811,0,0,1-.275,1.2,2.981,2.981,0,0,1-.824.949,4.344,4.344,0,0,1-1.323.624,6.312,6.312,0,0,1-1.748.225H495.1Zm2.047,8.964a3.848,3.848,0,0,0,2.147-.524,1.67,1.67,0,0,0,.774-1.473,1.237,1.237,0,0,0-.175-.674,1.436,1.436,0,0,0-.474-.474,2.084,2.084,0,0,0-.724-.275,4.98,4.98,0,0,0-.924-.1H497.4l-.924,3.545Zm.7-4.519a3.567,3.567,0,0,0,2.122-.549,1.712,1.712,0,0,0,.8-1.448,1.2,1.2,0,0,0-.524-1.074,2.554,2.554,0,0,0-1.5-.375h-2.8l-.9,3.446Z" transform="translate(-476.693 -583.526)" fill="#fff" />
                            <path id="Path_5975" data-name="Path 5975" d="M536.573,601.315a2.7,2.7,0,0,1-.749-.4,2.117,2.117,0,0,1-.524-.624,1.958,1.958,0,0,1-.2-.874,2.491,2.491,0,0,1,.924-1.972,3.442,3.442,0,0,1,1.049-.524,5.241,5.241,0,0,1,1.3-.2,8.088,8.088,0,0,1,1.4.125,10.777,10.777,0,0,1,1.2.275l.05-.175c.025-.1.05-.225.075-.35a1.765,1.765,0,0,0,.025-.35,1.32,1.32,0,0,0-.5-1.1,2.45,2.45,0,0,0-1.5-.374,4.99,4.99,0,0,0-1.1.1c-.325.075-.649.175-1,.275l-.175-.9a7.392,7.392,0,0,1,1.124-.325,6.95,6.95,0,0,1,1.3-.125,3.382,3.382,0,0,1,2.147.6,2.011,2.011,0,0,1,.749,1.673,3.764,3.764,0,0,1-.05.5,4.012,4.012,0,0,1-.125.524l-1.1,4.17h-1.074l.3-1.148a3.951,3.951,0,0,1-1.2.949,3.145,3.145,0,0,1-1.548.349A1.593,1.593,0,0,1,536.573,601.315Zm2.172-.874a3.1,3.1,0,0,0,.849-.449,3.708,3.708,0,0,0,.7-.7,2.479,2.479,0,0,0,.424-.9l.15-.524a6.556,6.556,0,0,0-1.1-.225,10.547,10.547,0,0,0-1.3-.1,3.4,3.4,0,0,0-.9.125,2.044,2.044,0,0,0-.7.349,1.371,1.371,0,0,0-.449.549,1.608,1.608,0,0,0-.175.7,1.177,1.177,0,0,0,.424.949,1.8,1.8,0,0,0,1.148.35A1.979,1.979,0,0,0,538.745,600.442Z" transform="translate(-506.798 -590.879)" fill="#fff" />
                            <path id="Path_5976" data-name="Path 5976" d="M570.7,582.2h1.1l-1.873,7.091,4.944-4.045h1.5l-3.945,3.146,2.022,4.195h-1.248l-1.723-3.5-1.973,1.573-.5,1.923h-1.1Z" transform="translate(-531.499 -582.175)" fill="#fff" />
                            <path id="Path_5977" data-name="Path 5977" d="M603.274,597.894v.15a2.067,2.067,0,0,0,2.222,2.222,3.183,3.183,0,0,0,1.248-.225,4.142,4.142,0,0,0,1.024-.649l.574.724a5.094,5.094,0,0,1-1.223.774,3.734,3.734,0,0,1-1.648.325,3.776,3.776,0,0,1-1.323-.225,2.873,2.873,0,0,1-1.024-.649,3.2,3.2,0,0,1-.674-1,3.979,3.979,0,0,1-.25-1.273,4.216,4.216,0,0,1,.325-1.648,4.972,4.972,0,0,1,.9-1.473,4.57,4.57,0,0,1,1.348-1.049,3.617,3.617,0,0,1,1.7-.4,3.056,3.056,0,0,1,1.224.225,2.832,2.832,0,0,1,.9.6,2.361,2.361,0,0,1,.549.9,3.123,3.123,0,0,1,.175,1.074,3.565,3.565,0,0,1-.075.724,6.707,6.707,0,0,1-.175.674H603.3A.7.7,0,0,0,603.274,597.894Zm4.894-.974a1.777,1.777,0,0,0,.075-.574,2.309,2.309,0,0,0-.1-.724,1.743,1.743,0,0,0-.325-.6,1.714,1.714,0,0,0-1.4-.574,2.872,2.872,0,0,0-1.024.2,2.693,2.693,0,0,0-.849.524,3.374,3.374,0,0,0-.649.8,4.263,4.263,0,0,0-.424.974Z" transform="translate(-557.312 -590.654)" fill="#fff" />
                            <path id="Path_5978" data-name="Path 5978" d="M637.573,592.437a2.63,2.63,0,0,1-.924-.624,3.256,3.256,0,0,1-.624-.974,3.4,3.4,0,0,1-.225-1.273,4.476,4.476,0,0,1,.35-1.823,4.359,4.359,0,0,1,.924-1.448,5.038,5.038,0,0,1,1.323-.974,3.689,3.689,0,0,1,1.548-.35,3.005,3.005,0,0,1,1,.15,2.736,2.736,0,0,1,1.348.974,3.886,3.886,0,0,1,.4.7l1.248-4.694h1.1l-2.747,10.412h-1.1l.325-1.248a5.213,5.213,0,0,1-1.2,1.024,2.962,2.962,0,0,1-1.573.424A4.251,4.251,0,0,1,637.573,592.437Zm2.672-1.049a3.576,3.576,0,0,0,1.049-.774,4.745,4.745,0,0,0,.749-1.1,2.964,2.964,0,0,0,.275-1.3,2.139,2.139,0,0,0-.175-.9,2.426,2.426,0,0,0-.474-.724,2.028,2.028,0,0,0-.724-.474,2.257,2.257,0,0,0-.924-.175,2.923,2.923,0,0,0-1.224.275,3.257,3.257,0,0,0-.974.774,3.581,3.581,0,0,0-.649,1.124,4.021,4.021,0,0,0-.225,1.348,2.658,2.658,0,0,0,.15.924,1.944,1.944,0,0,0,.424.7,1.606,1.606,0,0,0,.674.449,2.239,2.239,0,0,0,.849.15A2.581,2.581,0,0,0,640.244,591.388Z" transform="translate(-582.628 -582.1)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
            <path id="Path_5979" data-name="Path 5979" d="M405.14,643.9l-3.54,11.86h61.937l3.07-11.86Z" transform="translate(-9.754 -202.132)" fill="#f57f20" />
            <g id="Group_1726" data-name="Group 1726" transform="translate(401.198 443.915)">
                <path id="Path_5980" data-name="Path 5980" d="M443.1,653.6h2.1a4.432,4.432,0,0,1,1.223.15,1.959,1.959,0,0,1,.874.524,1.844,1.844,0,0,1,.524,1.373,2.3,2.3,0,0,1-.25,1.1,2.715,2.715,0,0,1-.674.824,2.8,2.8,0,0,1-1.024.5,4.244,4.244,0,0,1-1.273.175h-1.273l-.549,2.022H441.3Zm1.573,3.346a2.043,2.043,0,0,0,1.173-.3.994.994,0,0,0,.449-.874.734.734,0,0,0-.225-.549.94.94,0,0,0-.375-.225,1.716,1.716,0,0,0-.549-.075H444.2l-.549,2.022Z" transform="translate(-441.3 -653.325)" fill="#102548" />
                <path id="Path_5981" data-name="Path 5981" d="M468.748,652.5H470.2l-1.848,6.916H466.9Z" transform="translate(-460.508 -652.5)" fill="#102548" />
                <path id="Path_5982" data-name="Path 5982" d="M479.374,664.818a1.808,1.808,0,0,1-.474-.275,1.64,1.64,0,0,1-.3-.424,1.344,1.344,0,0,1-.1-.549,1.541,1.541,0,0,1,.175-.774,1.775,1.775,0,0,1,.474-.574,2.042,2.042,0,0,1,.7-.35,3.208,3.208,0,0,1,.874-.125,7.564,7.564,0,0,1,.774.05,6.715,6.715,0,0,1,.674.175l.025-.075a.463.463,0,0,1,.05-.175.487.487,0,0,0,.025-.2.533.533,0,0,0-.2-.424,1.372,1.372,0,0,0-.749-.175,3.39,3.39,0,0,0-.724.075,3.068,3.068,0,0,0-.624.2l-.225-1.148a5.359,5.359,0,0,1,1.9-.35,2.442,2.442,0,0,1,1.548.4,1.368,1.368,0,0,1,.524,1.149,2.147,2.147,0,0,1-.025.4l-.075.375-.749,2.8h-1.4l.15-.6a1.977,1.977,0,0,1-1.5.674A1.552,1.552,0,0,1,479.374,664.818Zm1.648-.974a1.49,1.49,0,0,0,.375-.225,1.85,1.85,0,0,0,.3-.325,1.149,1.149,0,0,0,.175-.4l.05-.125a2.105,2.105,0,0,0-.424-.125,3.4,3.4,0,0,0-.524-.05,1.213,1.213,0,0,0-.749.225.668.668,0,0,0-.3.574.5.5,0,0,0,.175.4.72.72,0,0,0,.474.15A2,2,0,0,0,481.022,663.845Z" transform="translate(-469.212 -657.902)" fill="#102548" />
                <path id="Path_5983" data-name="Path 5983" d="M499.524,666.417a4.976,4.976,0,0,1-.524-.25l.774-1.049a2.715,2.715,0,0,0,.275.15.634.634,0,0,0,.3.05.9.9,0,0,0,.275-.05,1.174,1.174,0,0,0,.275-.25l-.624-5.119h1.473l.25,3.446,1.9-3.446h1.548l-3.146,5.218a3.329,3.329,0,0,1-.974,1.1,2.116,2.116,0,0,1-1.124.325A2.32,2.32,0,0,1,499.524,666.417Z" transform="translate(-484.593 -658.052)" fill="#102548" />
                <path id="Path_5984" data-name="Path 5984" d="M522.7,658.743l1.1-.924a3.348,3.348,0,0,0,.849.649,2.21,2.21,0,0,0,1.024.225,1.245,1.245,0,0,0,.724-.2.552.552,0,0,0,.275-.5.728.728,0,0,0-.05-.225c-.025-.075-.1-.125-.175-.2a1.438,1.438,0,0,0-.35-.2c-.15-.075-.325-.15-.549-.25a4.812,4.812,0,0,1-.624-.3,2.809,2.809,0,0,1-.524-.375,1.394,1.394,0,0,1-.35-.5,1.315,1.315,0,0,1-.125-.649,1.875,1.875,0,0,1,.225-.924,2.212,2.212,0,0,1,.574-.7,2.467,2.467,0,0,1,.849-.424,3.337,3.337,0,0,1,1-.15,3.692,3.692,0,0,1,1.473.275,3.042,3.042,0,0,1,1.024.724l-1,1a3.415,3.415,0,0,0-.749-.524,1.845,1.845,0,0,0-.849-.175,1.092,1.092,0,0,0-.724.225.6.6,0,0,0-.25.449.5.5,0,0,0,.05.225.754.754,0,0,0,.2.2,2.176,2.176,0,0,0,.35.2c.15.075.325.15.574.25.225.1.449.2.624.3a1.869,1.869,0,0,1,.5.375,1.463,1.463,0,0,1,.325.474,1.637,1.637,0,0,1,.125.624,1.874,1.874,0,0,1-.225.924,2.608,2.608,0,0,1-.574.7,2.467,2.467,0,0,1-.849.424,3.339,3.339,0,0,1-1,.15A3.248,3.248,0,0,1,522.7,658.743Z" transform="translate(-502.376 -652.95)" fill="#102548" />
                <path id="Path_5985" data-name="Path 5985" d="M549.023,652.5h1.448l-.624,2.372a2.75,2.75,0,0,1,.624-.424,1.55,1.55,0,0,1,.774-.2,1.49,1.49,0,0,1,1.1.4,1.452,1.452,0,0,1,.4,1.1,3.421,3.421,0,0,1-.05.474,3.66,3.66,0,0,1-.125.5l-.724,2.722H550.4l.749-2.8c.025-.075.025-.15.05-.225a.692.692,0,0,0,.025-.225.621.621,0,0,0-.175-.474.7.7,0,0,0-.524-.15.981.981,0,0,0-.7.275,1.535,1.535,0,0,0-.4.749l-.774,2.871H547.2Z" transform="translate(-520.759 -652.5)" fill="#102548" />
                <path id="Path_5986" data-name="Path 5986" d="M571.974,664.818a1.808,1.808,0,0,1-.474-.275,1.641,1.641,0,0,1-.3-.424,1.343,1.343,0,0,1-.1-.549,1.541,1.541,0,0,1,.175-.774,1.775,1.775,0,0,1,.474-.574,2.043,2.043,0,0,1,.7-.35,3.208,3.208,0,0,1,.874-.125,7.564,7.564,0,0,1,.774.05,6.714,6.714,0,0,1,.674.175l.025-.075a.462.462,0,0,1,.05-.175.487.487,0,0,0,.025-.2.533.533,0,0,0-.2-.424,1.372,1.372,0,0,0-.749-.175,3.391,3.391,0,0,0-.724.075,3.07,3.07,0,0,0-.624.2l-.225-1.148a5.358,5.358,0,0,1,1.9-.35,2.442,2.442,0,0,1,1.548.4,1.367,1.367,0,0,1,.524,1.149,2.153,2.153,0,0,1-.025.4l-.075.375-.749,2.8h-1.4l.15-.6a1.977,1.977,0,0,1-1.5.674A1.6,1.6,0,0,1,571.974,664.818Zm1.648-.974a1.49,1.49,0,0,0,.375-.225,1.851,1.851,0,0,0,.3-.325,1.148,1.148,0,0,0,.175-.4l.05-.125a2.1,2.1,0,0,0-.424-.125,3.4,3.4,0,0,0-.524-.05,1.213,1.213,0,0,0-.749.225.668.668,0,0,0-.3.574.5.5,0,0,0,.175.4.72.72,0,0,0,.474.15A2,2,0,0,0,573.622,663.845Z" transform="translate(-538.691 -657.902)" fill="#102548" />
                <path id="Path_5987" data-name="Path 5987" d="M594.448,659.486H595.9l-.275,1.024a3.16,3.16,0,0,1,.849-.874,1.757,1.757,0,0,1,1.1-.25l-.4,1.5h-.1a1.986,1.986,0,0,0-1.248.424,2.779,2.779,0,0,0-.8,1.423l-.474,1.8H593.1Z" transform="translate(-555.198 -657.663)" fill="#102548" />
                <path id="Path_5988" data-name="Path 5988" d="M612.373,663.37a1.193,1.193,0,0,0,.849.275,1.343,1.343,0,0,0,.549-.1,2.741,2.741,0,0,0,.574-.325l.674.9a2.644,2.644,0,0,1-.824.474,3.327,3.327,0,0,1-2.122.05,1.88,1.88,0,0,1-.749-.449,2.3,2.3,0,0,1-.474-.7,2.8,2.8,0,0,1-.15-.949,2.88,2.88,0,0,1,.225-1.1,2.832,2.832,0,0,1,.624-.974,2.929,2.929,0,0,1,.949-.7,2.868,2.868,0,0,1,1.248-.275,2.375,2.375,0,0,1,.874.15,1.952,1.952,0,0,1,.649.424,1.787,1.787,0,0,1,.4.624,2.069,2.069,0,0,1,.15.8,2.417,2.417,0,0,1-.075.574,2.816,2.816,0,0,1-.2.6h-3.471A.945.945,0,0,0,612.373,663.37Zm2.022-1.648a1.462,1.462,0,0,0,.05-.325.805.805,0,0,0-.2-.524.817.817,0,0,0-.649-.225,1.374,1.374,0,0,0-.874.3,1.756,1.756,0,0,0-.549.774Z" transform="translate(-568.404 -657.752)" fill="#102548" />
            </g>
            <circle id="Ellipse_5170" data-name="Ellipse 5170" cx="3.62" cy="3.62" r="3.62" transform="translate(388.6 448.509)" fill="#fff" />
        </g>
    </svg>

);
const Wallet = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_1760" data-name="Group 1760" transform="translate(-1603 -28)">
            <path id="Subtraction_1" data-name="Subtraction 1" d="M18,3A16,16,0,1,1,2,19,16,16,0,0,1,18,3Z" transform="translate(1601 25)" fill="#f57f20" />
            <g id="vuesax_bold_wallet" data-name="vuesax/bold/wallet" transform="translate(1375 -408)">
                <g id="wallet" transform="translate(236 444)">
                    <path id="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
                    <path id="Vector-2" data-name="Vector" d="M2,.333V1a.333.333,0,0,1-.327.333H.7A.69.69,0,0,1,0,.727.666.666,0,0,1,.2.193.634.634,0,0,1,.663,0H1.67A.334.334,0,0,1,2,.333Z" transform="translate(12.297 8.76)" fill="#fff" />
                    <path id="Vector-3" data-name="Vector" d="M10.36,3.867A1.347,1.347,0,0,0,10,5.187,1.453,1.453,0,0,0,11.42,6.2H12a.669.669,0,0,1,.667.667v.127A2.512,2.512,0,0,1,10.16,9.5H2.507A2.512,2.512,0,0,1,0,6.993V2.507a2.49,2.49,0,0,1,1-2A2.471,2.471,0,0,1,2.507,0H10.16a2.512,2.512,0,0,1,2.507,2.507V2.8A.669.669,0,0,1,12,3.467h-.68a1.329,1.329,0,0,0-.96.4Z" transform="translate(1.633 4.593)" fill="#fff" />
                    <path id="Vector-4" data-name="Vector" d="M5.68,1.88a.275.275,0,0,1-.227.46L.333,2.333A.331.331,0,0,1,.1,1.767L1.18.68a2.35,2.35,0,0,1,3.307,0L5.653,1.86C5.66,1.867,5.673,1.873,5.68,1.88Z" transform="translate(5.12 1.333)" fill="#fff" />
                </g>
            </g>
        </g>
    </svg>
);
const Facebook = ({ className }) => (
    <svg className={className} id="Group_1533" data-name="Group 1533" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <rect id="Rectangle_128" data-name="Rectangle 128" width="46" height="46" rx="23" fill="rgba(4,10,20,0.24)" opacity="0.997" />
        <g id="twitter" transform="translate(14 16)">
            <g id="Group_160" data-name="Group 160">
                <path id="Path_266" data-name="Path 266" d="M18.462,49.776a7.891,7.891,0,0,1-2.181.6,3.764,3.764,0,0,0,1.665-2.092,7.564,7.564,0,0,1-2.4.916A3.785,3.785,0,0,0,9,51.786a3.9,3.9,0,0,0,.088.863,10.713,10.713,0,0,1-7.8-3.959,3.786,3.786,0,0,0,1.163,5.058,3.738,3.738,0,0,1-1.71-.466v.042a3.8,3.8,0,0,0,3.032,3.719,3.778,3.778,0,0,1-.992.125,3.346,3.346,0,0,1-.717-.065A3.821,3.821,0,0,0,5.6,59.739,7.6,7.6,0,0,1,.906,61.353,7.089,7.089,0,0,1,0,61.3,10.655,10.655,0,0,0,5.806,63,10.7,10.7,0,0,0,16.578,52.23c0-.167-.006-.329-.014-.489A7.551,7.551,0,0,0,18.462,49.776Z" transform="translate(0 -48)" fill="#fff" />
            </g>
        </g>
    </svg>
);
const Twitter = ({ className }) => (
    <svg className={className} id="Group_1531" data-name="Group 1531" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <rect id="Rectangle_130" data-name="Rectangle 130" width="46" height="46" rx="23" fill="rgba(4,10,20,0.24)" opacity="0.997" />
        <path id="Path_269" data-name="Path 269" d="M5.883,14.655l19.04-7.341c.884-.319,1.656.216,1.369,1.552h0l-3.24,15.272c-.24,1.083-.884,1.346-1.784.836l-4.937-3.639L13.95,23.629a1.245,1.245,0,0,1-1,.485L13.3,19.09l9.15-8.266c.4-.351-.089-.548-.614-.2l-11.3,7.12-4.875-1.52c-1.058-.336-1.081-1.058.224-1.568Z" transform="translate(7.117 6.775)" fill="#fff" />
    </svg>
);
const Instagram = ({ className }) => (
    <svg className={className} id="Group_1532" data-name="Group 1532" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <rect id="Rectangle_129" data-name="Rectangle 129" width="46" height="46" rx="23" fill="rgba(4,10,20,0.24)" opacity="0.997" />
        <g id="instagram" transform="translate(14 14)">
            <g id="Group_162" data-name="Group 162">
                <g id="Group_161" data-name="Group 161">
                    <path id="Path_267" data-name="Path 267" d="M12.375,0H5.625A5.626,5.626,0,0,0,0,5.624v6.751A5.626,5.626,0,0,0,5.624,18h6.751A5.626,5.626,0,0,0,18,12.376V5.625A5.626,5.626,0,0,0,12.376,0Zm3.938,12.375a3.942,3.942,0,0,1-3.937,3.938H5.625a3.942,3.942,0,0,1-3.937-3.937V5.625A3.942,3.942,0,0,1,5.625,1.688h6.75a3.942,3.942,0,0,1,3.938,3.938Z" fill="#fff" />
                </g>
            </g>
            <g id="Group_164" data-name="Group 164" transform="translate(4.5 4.5)">
                <g id="Group_163" data-name="Group 163">
                    <path id="Path_268" data-name="Path 268" d="M132.5,128a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,132.5,128Zm0,7.313a2.813,2.813,0,1,1,2.813-2.813,2.813,2.813,0,0,1-2.813,2.813Z" transform="translate(-128 -128)" fill="#fff" />
                </g>
            </g>
            <g id="Group_166" data-name="Group 166" transform="translate(13.238 3.563)">
                <g id="Group_165" data-name="Group 165">
                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="0.6" cy="0.6" r="0.6" transform="translate(0 0)" fill="#fff" />
                </g>
            </g>
        </g>
    </svg>
);
const Github = ({ className }) => (
    <svg className={className} id="Group_1530" data-name="Group 1530" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <rect id="Rectangle_131" data-name="Rectangle 131" width="46" height="46" rx="23" fill="rgba(4,10,20,0.24)" opacity="0.997" />
        <g id="github" transform="translate(14 14)">
            <g id="Group_167" data-name="Group 167">
                <path id="Path_270" data-name="Path 270" d="M9.19,5.329a9.233,9.233,0,0,0-2.9,17.984c.46.085.627-.2.627-.445,0-.219-.008-.8-.013-1.569-2.556.557-3.1-1.237-3.1-1.237a2.437,2.437,0,0,0-1.02-1.35c-.835-.572.062-.561.062-.561a1.93,1.93,0,0,1,1.407.951,1.953,1.953,0,0,0,2.676.766,1.97,1.97,0,0,1,.584-1.234c-2.04-.233-4.187-1.025-4.187-4.56A3.577,3.577,0,0,1,4.274,11.6a3.325,3.325,0,0,1,.09-2.441s.772-.248,2.528.946a8.676,8.676,0,0,1,4.6,0c1.754-1.194,2.525-.946,2.525-.946a3.33,3.33,0,0,1,.092,2.441,3.57,3.57,0,0,1,.944,2.477c0,3.544-2.148,4.325-4.2,4.553a2.207,2.207,0,0,1,.624,1.708c0,1.234-.011,2.229-.011,2.531,0,.247.165.534.632.443A9.234,9.234,0,0,0,9.19,5.329Z" transform="translate(0 -5.329)" fill="#fff" />
            </g>
        </g>
    </svg>
);
const Discord = ({ className }) => (
    <svg className={className} id="Group_1529" data-name="Group 1529" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <rect id="Rectangle_132" data-name="Rectangle 132" width="46" height="46" rx="23" fill="rgba(4,10,20,0.24)" opacity="0.997" />
        <g id="discord" transform="translate(12 14)">
            <path id="Path_271" data-name="Path 271" d="M21.071,56.659a19.579,19.579,0,0,0-1.9-4.646A11.72,11.72,0,0,0,14.3,49l-.45,1.224a13.722,13.722,0,0,1,3.4,1.789,22.393,22.393,0,0,0-6.117-.908,22.393,22.393,0,0,0-6.117.908,13.722,13.722,0,0,1,3.4-1.789L7.964,49a11.72,11.72,0,0,0-4.872,3.009,19.576,19.576,0,0,0-1.9,4.646A43.011,43.011,0,0,0,0,63.9c.076.116,1.826,3.1,6.6,3.1l1.2-1.747a12.856,12.856,0,0,1-3.828-1.726L4.7,62.441a11.629,11.629,0,0,0,12.863,0l.723,1.086a12.856,12.856,0,0,1-3.828,1.726L15.666,67c4.769,0,6.519-2.988,6.6-3.107a43.014,43.014,0,0,0-1.2-7.234ZM8.115,60.071h-1.3V57.9h1.3Zm7.336,0h-1.3V57.9h1.3Z" transform="translate(0 -49.004)" fill="#fff" />
        </g>
    </svg>

);
const Ethereum = ({ className }) => (
    <svg className={className} width="58" height="96" viewBox="0 0 58 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M29.4946 35.4937L0.957275 48.888L29.4946 66.3315L57.9786 48.9211L29.4946 35.4937Z" fill="white" />
            </g>
        </g>
        <g opacity="0.45">
            <g opacity="0.45">
                <path opacity="0.45" d="M0.957275 48.8882L29.4946 66.3317V0L0.957275 48.8882Z" fill="white" />
            </g>
        </g>
        <g opacity="0.8">
            <g opacity="0.8">
                <path opacity="0.8" d="M29.4946 0V66.3317L57.9786 48.9212L29.4946 0Z" fill="white" />
            </g>
        </g>
        <g opacity="0.45">
            <g opacity="0.45">
                <path opacity="0.45" d="M0.957275 54.4712L29.4519 95.9892V71.8485L0.957275 54.4712Z" fill="white" />
            </g>
        </g>
        <g opacity="0.8">
            <g opacity="0.8">
                <path opacity="0.8" d="M29.4946 71.8482V95.9447L57.9999 54.4268L29.4946 71.8482Z" fill="white" />
            </g>
        </g>
    </svg>
);
const Polygon = ({ className }) => (
    <svg className={className} width="96" height="83" viewBox="0 0 96 83" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.4428 25.1381C70.6713 24.0943 68.3688 24.0943 66.4208 25.1381L52.605 33.1405L43.2176 38.3596L29.4022 46.3618C27.631 47.4056 25.3284 47.4056 23.3801 46.3618L12.3985 40.0989C10.6273 39.0552 9.38745 37.1417 9.38745 35.0541V22.7025C9.38745 20.6149 10.4502 18.7013 12.3985 17.6575L23.2029 11.5687C24.9741 10.5249 27.2767 10.5249 29.2251 11.5687L40.0296 17.6575C41.8006 18.7013 43.0406 20.6149 43.0406 22.7025V30.705L52.428 25.312V17.3096C52.428 15.222 51.3654 13.3084 49.417 12.2646L29.4022 0.782846C27.631 -0.260949 25.3284 -0.260949 23.3801 0.782846L3.01107 12.2646C1.06273 13.3084 0 15.222 0 17.3096V40.4471C0 42.5346 1.06273 44.4484 3.01107 45.4922L23.3801 56.9739C25.1513 58.0176 27.4539 58.0176 29.4022 56.9739L43.2176 49.1453L52.605 43.7524L66.4208 35.9239C68.1918 34.8801 70.4943 34.8801 72.4428 35.9239L83.2472 42.0127C85.0182 43.0565 86.2582 44.9703 86.2582 47.0578V59.4092C86.2582 61.4971 85.1956 63.4105 83.2472 64.4542L72.4428 70.7172C70.6713 71.7609 68.3688 71.7609 66.4208 70.7172L55.6164 64.6283C53.845 63.5846 52.605 61.6708 52.605 59.5833V51.581L43.2176 56.9739V64.9761C43.2176 67.0636 44.2805 68.9774 46.2286 70.0212L66.5978 81.5029C68.3688 82.5467 70.6713 82.5467 72.6198 81.5029L92.989 70.0212C94.76 68.9774 96 67.0636 96 64.9761V41.8386C96 39.7511 94.9371 37.8377 92.989 36.7939L72.4428 25.1381Z" fill="white" fillOpacity="0.4" />
    </svg>
);


const Icon = props => {
    let El = Logo;
    switch (props.name) {
        case "wallet":
            El = Wallet;
            break;
        case "facebook":
            El = Facebook;
            break;
        case "twitter":
            El = Twitter;
            break;
        case "instagram":
            El = Instagram;
            break;
        case "github":
            El = Github;
            break;
        case "discord":
            El = Discord;
            break;
        case "ethereum":
            El = Ethereum;
            break;
        case "polygon":
            El = Polygon;
            break;
        default:
            El = Logo;
            break;
    }
    return (
        <El
            className={`rvt-icon rat-icon-${props.name} ${props.className}`.trim()}
        />
    );
};

Icon.propTypes = {
    name: PropTypes.string
};

export default Icon;