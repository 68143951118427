import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Icon from '../Components/Icon';

const DefaultHome = () => {
    return (<>
        <Container>
            <Row className="justify-content-center">
                <Col md={12} lg={12} className="mb-4" >
                    <Button variant="link" className="PR_StakingOn">
                        <div className="PR_StakingOnInner">
                            <div className="text-white pt-3 PR_StakingOn">
                                <h2>ReBaked is expected to release a new staking contract soon. 

                                The current staking contract will be retired. If you have your tokens staked, you can unstake them at any time, but new users won't be able to stake their tokens. 

                                A snapshot of current staking participants was taken on September 7th, and this will be used for the first tickets distributed for all our projects.
                                </h2>
                            </div>
                        </div>
                    </Button>
                </Col>
                <Col md={6} lg={4} className="mb-4" onClick={() => window.location.href = process.env.REACT_APP_ETH_PATH}>
                    <Button variant="link" className="PR_StakingOn">
                        <div className="PR_StakingOnInner">
                            <div className="PR_StakingOnIcon">
                                <Icon name="ethereum" />
                            </div>
                            <div className="fs-xxl-20px fs-16px text-white ffTitle pt-3">Staking On</div>
                        </div>
                        <div className="fs-xxl-16px fs-14px text-white text-center PR_StakingOnName">Ethereum</div>
                    </Button>
                </Col>
                <Col md={6} lg={4} className="mb-4" onClick={() => window.location.href = process.env.REACT_APP_POLYGON_PATH}>
                    <Button variant="link" className="PR_StakingOn">
                        <div className="PR_StakingOnInner">
                            <div className="PR_StakingOnIcon">
                                <Icon name="polygon" />
                            </div>
                            <div className="fs-xxl-20px fs-16px text-white ffTitle pt-3">Staking On</div>
                        </div>
                        <div className="fs-xxl-16px fs-14px text-white text-center PR_StakingOnName">Polygon</div>
                    </Button>
                </Col>
            </Row>
        </Container>
    </>)
}
export default DefaultHome